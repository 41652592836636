.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--backdrop);
  z-index: 100;

  .loadingIcon {
    margin-top: 16px;
    font-size: 4em;
  }
}