.logo {
  display: block;
  padding: 16px;
  border-right: 1px solid #f0f0f0;
  color: white;
  background-color: var(--point400);
  font-weight: 700;

  img,
  span {
    line-height: 20px;
    height: 20px;
  }

  img {
    width: 100%;
  }
}