html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

:root {
  --desktop-max-width: 540px;
  --orange400: #E68819;
  --orange500: #DA7B11;
  --orange600: #CB6F10;
  --orange700: #BD640D;

  --red400: #E34850;
  --red500: #D7373F;
  --red600: #C9252D;
  --red700: #BB121A;

  --point400: #00bfa5;

  --gray75: #FAFAFA;
  --gray100: #F5F5F5;
  --gray200: #EAEAEA;
  --gray300: #E1E1E1;
  --gray400: #C4C4C4;
  --gray500: #B3B3B3;
  --gray600: #8E8E8E;
  --gray700: #6E6E6E;
  --gray800: #4B4B4B;
  --gray900: #2C2C2C;

  --backdrop: rgba(255, 255, 255, 0.4);
  --unable: rgba(0, 0, 0, 0.4);
  --cardShadow: rgb(0 0 33 / 7%) 0px 16px 22.4px 4.8px, rgb(0 0 33 / 5%) 0px 3.2px 16px 0px, rgb(0 0 33 / 7%) 0px 0px 1px 0px;
  --white: white;
}

.loading {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  background-color: var(--backdrop);
}

.height100 {
  height: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}