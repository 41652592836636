.appLayout {
  .pageHeader {
    padding: 10px 20px;
    height: 54px;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .session {
      font-size: 12px;
      letter-spacing: -0.05em;
      color: var(--gray700);
      margin-right: 4px;
    }
  }

  .contentContainer {
    height: 100%;
  }
}

.pageHeaderContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageTitle {
  flex: 1;
  text-align: center;
}